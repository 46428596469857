<template>
  <div class="job-responsibilities-item">
    <div
      :class="[
        responsibility.current_user_responsibilities
          ? 'job-responsibilities-item__header_resp'
          : '',
        'job-responsibilities-item__header'
      ]"
      @click="handleShowContent"
    >
      <i class="icon-folder"></i>
      <span :class="'job-responsibilities-item__header-title'">{{ responsibility.name }}</span>
      <i
        class="icon-down"
        :class="activeContent"
        v-if="responsibility.current_user_responsibilities"
      ></i>
    </div>
    <transition name="fade">
      <div v-show="showContent" class="job-responsibilities-item__content">
        <JobResponsibilitiesCurrent
          v-for="current in responsibility.current_user_responsibilities"
          :key="current.id"
          :current="current"
        />
      </div>
    </transition>
  </div>
</template>

<script>
const JobResponsibilitiesCurrent = () =>
  import(
    "@/components/ProfileComponents/JobFolderComponents/JobResponsibilities/JobResponsibilitiesCurrent"
  );

export default {
  name: "JobResponsibilitiesItem",
  mixins: [],
  props: {
    responsibility: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: { JobResponsibilitiesCurrent },
  data() {
    return {
      showContent: false
    };
  },
  mounted() {},
  methods: {
    handleShowContent() {
      if (this.responsibility.current_user_responsibilities) this.showContent = !this.showContent;
    }
  },
  computed: {
    activeContent() {
      return this.showContent ? "active" : "";
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";

.fade-enter-active,
.fade-leave-active {
  transition: all 0.1s ease;
}

.fade-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.job-responsibilities-item {
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $color-button-click3;

  &__header {
    display: flex;
    align-items: center;
    user-select: none;
    &_resp {
      cursor: pointer;

      &:hover {
        .job-responsibilities-item__header-title {
          color: $color-interactive;
        }
      }
      &:active {
        .job-responsibilities-item__header-title {
          color: $color-interactive;
        }
      }
    }

    .icon-folder {
      margin-right: 10px;
      color: $color-interactive;
      font-size: 20px;
    }

    .icon-down {
      transition: all 0.1s ease;
      font-size: 20px;
    }

    .active {
      transform: rotate(180deg);
    }

    &-title {
      margin-right: 15px;

      color: $color-text-accent;
      word-break: break-word;
      line-height: 20px;
      font-weight: 400;
      font-size: 14px;

      transition: all 0.1s ease;

      &.active {
        color: $color-interactive;
      }
    }
  }

  &__content {
    margin: 25px 0 10px 5px;
    display: flex;
    flex-direction: column;

    .job-responsibilities-current {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
